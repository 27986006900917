
import { computed, defineComponent } from 'vue'
import { useStore } from '@/utils/composables'
import { getV2Route } from '@/service/shared.http'

import { MyndshftPanel } from '@myndshft/panel'
import LoginButton from '@/components/auth/login-button.vue'

export default defineComponent({
	components: {
		MyndshftPanel,
		LoginButton,
	},
	setup() {
		const store = useStore()
		const isLoggedIn = computed(() => store.getters['appUser/isLoggedIn'])
		const isPendingLogout = computed(
			() => store.getters['appUser/isPendingLogout']
		)
		const hasPermissions = computed(
			() => store.getters['appUser/hasPermissions']
		)

		return {
			isLoggedIn,
			isPendingLogout,
			hasPermissions,
			getV2Route,
		}
	},
})
