
import { defineComponent, onMounted } from 'vue'
import { useStore } from '@/utils/composables'

export default defineComponent({
	setup() {
		const store = useStore()

		onMounted(() => {
			window.google.accounts.id.initialize({
				client_id: `${store.state.environment.googleClientId}.apps.googleusercontent.com`,
				auto_select: true,
				use_fedcm_for_prompt: true,
				callback: (user: any) => {
					store.dispatch('appUser/login', user)
				},
			})
			window.google.accounts.id.renderButton(
				document.getElementById('sign-in-button'),
				{
					theme: 'outline',
					size: 'large',
				}
			)
		})
	},
})
